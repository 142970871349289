import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
	(state) => state.authorization,
	(authorization) => authorization
);
export const getCurrentRoute = createSelector(
	(state) => state.currentRoute,
	(currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
	getCurrentRoute,
	(route) => route.title
);
export const getSideMenuState = createSelector(
	(state) => state.app,
	(app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
	(state) => state.currentTheme,
	(currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
	(state) => state.profile,
	(profile) => profile
);
export const currentUserId = createSelector(
	(state) => state.profile,
	(profile) => profile && profile._id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
	!profile ? null : profile.role
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
	(state) => state.notifications,
	(notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
	(state) => state.users,
	(users) => users
);
export const getUsersForm = createSelector(
	(state) => state.usersForm,
	(form) => form
);

/* ALERT */
export const getAlerts = createSelector(
	(state) => state.alerts,
	(alerts) => alerts
);
export const getAlertsForm = createSelector(
	(state) => state.alertsForm,
	(form) => form
);

/* TIMEREQUESTS */
export const getTimeRequests = createSelector(
	(state) => state.timeRequests,
	(timeRequests) => timeRequests
);
export const getTimeRequestsForm = createSelector(
	(state) => state.timeRequestsForm,
	(form) => form
);

/* HOLIDAYREQUESTS */
export const getHolidayRequests = createSelector(
	(state) => state.holidayRequests,
	(holidayRequests) => holidayRequests
);
export const getHolidayRequestsForm = createSelector(
	(state) => state.holidayRequestsForm,
	(form) => form
);

/* LEAVES */
export const getLeaves = createSelector(
	(state) => state.leaves,
	(leaves) => leaves
);
export const getLeavesForm = createSelector(
	(state) => state.leavesForm,
	(form) => form
);

/* SINGUPS */
export const getSingUps = createSelector(
	(state) => state.singUps,
	(singUps) => singUps
);
export const getSingUpsForm = createSelector(
	(state) => state.singUpsForm,
	(form) => form
);

/* ESTABLISHMENTINFOS */
export const getEstablishmentInfo = createSelector(
	(state) => state.establishmentInfo,
	(establishmentInfo) => establishmentInfo
);
export const getEstablishmentInfoForm = createSelector(
	(state) => state.establishmentInfoForm,
	(form) => form
);
/* BUSINESSS */
export const getBusinesss = createSelector(
	(state) => state.businesss,
	(businesss) => businesss
);
export const getBusinesssForm = createSelector(
	(state) => state.businesssForm,
	(form) => form
);

/* PROJECTS */
export const getProjects = createSelector(
	(state) => state.projects,
	(projects) => projects
);
export const getProjectsForm = createSelector(
	(state) => state.projectsForm,
	(form) => form
);

/* ACTIVITYS */
export const getActivitys = createSelector(
	(state) => state.activitys,
	(activitys) => activitys
);
export const getActivitysForm = createSelector(
	(state) => state.activitysForm,
	(form) => form
);

/* HOURS */
export const getHours = createSelector(
	(state) => state.hours,
	(hours) => hours
);
export const getHoursForm = createSelector(
	(state) => state.hoursForm,
	(form) => form
);

/* CONFIGURATIONS */
export const getConfigurations = createSelector(
	(state) => state.configurations,
	(configurations) => configurations
);
export const getConfigurationsForm = createSelector(
	(state) => state.configurationsForm,
	(form) => form
);

/* ESTABLISHMENTS */
export const getEstablishments = createSelector(
	(state) => state.establishments,
	(establishments) => establishments
);
export const getEstablishmentsForm = createSelector(
	(state) => state.establishmentsForm,
	(form) => form
);

/* SUGGESTIONS */
export const getSuggestions = createSelector(
	(state) => state.suggestions,
	(suggestions) => suggestions
);
export const getSuggestionsForm = createSelector(
	(state) => state.suggestionsForm,
	(form) => form
);

/* UNITYS */
export const getUnitys = createSelector(
	(state) => state.unitys,
	(unitys) => unitys
);
export const getUnitysForm = createSelector(
	(state) => state.unitysForm,
	(form) => form
);

/* LOCATIONS */
export const getLocations = createSelector(
	(state) => state.locations,
	(locations) => locations
);
export const getLocationsForm = createSelector(
	(state) => state.locationsForm,
	(form) => form
);

/* PARAMETERS */
export const getParameters = createSelector(
	(state) => state.parameters,
	(parameters) => parameters
);
export const getParametersForm = createSelector(
	(state) => state.parametersForm,
	(form) => form
);

/* LEGISLATIONS */
export const getLegislations = createSelector(
	(state) => state.legislations,
	(legislations) => legislations
);
export const getLegislationsForm = createSelector(
	(state) => state.legislationsForm,
	(form) => form
);

/* CONTAINERS */
export const getContainers = createSelector(
	(state) => state.containers,
	(containers) => containers
);
export const getContainersForm = createSelector(
	(state) => state.containersForm,
	(form) => form
);

/* SERVICES */
export const getServices = createSelector(
	(state) => state.services,
	(services) => services
);
export const getServicesForm = createSelector(
	(state) => state.servicesForm,
	(form) => form
);

/* SAMPLINGPOINTS */
export const getSamplingpoints = createSelector(
	(state) => state.samplingpoints,
	(samplingpoints) => samplingpoints
);
export const getSamplingpointsForm = createSelector(
	(state) => state.samplingpointsForm,
	(form) => form
);

/* PLANNINGS */
export const getPlannings = createSelector(
	(state) => state.plannings,
	(plannings) => plannings
);
export const getPlanningsForm = createSelector(
	(state) => state.planningsForm,
	(form) => form
);

/* MATRIXS */
export const getMatrixs = createSelector(
	(state) => state.matrixs,
	(matrixs) => matrixs
);
export const getMatrixsForm = createSelector(
	(state) => state.matrixsForm,
	(form) => form
);

/* FEES */
export const getFees = createSelector(
	(state) => state.fees,
	(fees) => fees
);
export const getFeesForm = createSelector(
	(state) => state.feesForm,
	(form) => form
);

/* SAMPLINGS */
export const getSamplings = createSelector(
	(state) => state.samplings,
	(samplings) => samplings
);
export const getSamplingsForm = createSelector(
	(state) => state.samplingsForm,
	(form) => form
);

/* COLUMNS */
export const getColumns = createSelector(
	(state) => state.columns,
	(columns) => columns
);
export const getColumnsForm = createSelector(
	(state) => state.columnsForm,
	(form) => form
);

/* BUSINESSALERTS */
export const getBusinessAlerts = createSelector(
	(state) => state.businessAlerts,
	(businessAlerts) => businessAlerts
);
export const getBusinessAlertsForm = createSelector(
	(state) => state.businessAlertsForm,
	(form) => form
);

/* STOCKS */
export const getStocks = createSelector(
	(state) => state.stocks,
	(stocks) => stocks
);
export const getStocksForm = createSelector(
	(state) => state.stocksForm,
	(form) => form
);

/* EQUIPMENTS */
export const getEquipments = createSelector(
	(state) => state.equipments,
	(equipments) => equipments
);
export const getEquipmentsForm = createSelector(
	(state) => state.equipmentsForm,
	(form) => form
);

/* MAINTENANCES */
export const getMaintenances = createSelector(
	(state) => state.maintenances,
	(maintenances) => maintenances
);
export const getMaintenancesForm = createSelector(
	(state) => state.maintenancesForm,
	(form) => form
);

/* SOCIETYS */
export const getSocietys = createSelector(
	(state) => state.societys,
	(societys) => societys
);
export const getSocietysForm = createSelector(
	(state) => state.societysForm,
	(form) => form
);

/* LABS */
export const getLabs = createSelector(
	(state) => state.labs,
	(labs) => labs
);
export const getLabsForm = createSelector(
	(state) => state.labsForm,
	(form) => form
);

/* CONSULTORS */
export const getConsultors = createSelector(
	(state) => state.consultors,
	(consultors) => consultors
);
export const getConsultorsForm = createSelector(
	(state) => state.consultorsForm,
	(form) => form
);

/* CONSULTINVOICEPLANNINGS */
export const getConsultInvoicePlannings = createSelector(
	(state) => state.consultInvoicePlannings,
	(consultInvoicePlannings) => consultInvoicePlannings
);
export const getConsultInvoicePlanningsForm = createSelector(
	(state) => state.consultInvoicePlanningsForm,
	(form) => form
);

/* CONSULTINVOICES */
export const getConsultInvoices = createSelector(
	(state) => state.consultInvoices,
	(consultInvoices) => consultInvoices
);
export const getConsultInvoicesForm = createSelector(
	(state) => state.consultInvoicesForm,
	(form) => form
);

/* ROLES */
export const getRoles = createSelector(
	(state) => state.roles,
	(roles) => roles
);
export const getRolesForm = createSelector(
	(state) => state.rolesForm,
	(form) => form
);

/* IVAS */
export const getIvas = createSelector(
	(state) => state.ivas,
	(ivas) => ivas
);
export const getIvasForm = createSelector(
	(state) => state.ivasForm,
	(form) => form
);

/* ADITIONALINFORMATIONS */
export const getAditionalInformations = createSelector(
	(state) => state.aditionalInformations,
	(aditionalInformations) => aditionalInformations
);
export const getAditionalInformationsForm = createSelector(
	(state) => state.aditionalInformationsForm,
	(form) => form
);

/* OTHERADITIONALINFORMATIONS */
export const getOtherAditionalInformations = createSelector(
	(state) => state.otherAditionalInformations,
	(otherAditionalInformations) => otherAditionalInformations
);
export const getOtherAditionalInformationsForm = createSelector(
	(state) => state.otherAditionalInformationsForm,
	(form) => form
);

/* LOGSACCES */
export const getLogsAcces = createSelector(
	(state) => state.logsAcces,
	(logsAcces) => logsAcces
);
export const getLogsAccesForm = createSelector(
	(state) => state.logsAccesForm,
	(form) => form
);
