import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import { ADD_NEW_SAMPLING, DELETE_SAMPLING, SAMPLING_FORM_TOOGLE_LOADING, SET_SAMPLING_LIST, SHOW_NOTIFICATION, UPDATE_SAMPLING } from "../constants";
import { formatSamplings } from "./settingsActionsUtils";

/* SAMPLING LIST */
export const fetchSamplings = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.samplings.list)
			.then((response) => {
				const samplings = formatSamplings(response.data);
				dispatch({
					type: SET_SAMPLING_LIST,
					payload: keyBy(samplings, "_id"),
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};

// SAMPLINGS
export const createSampling = (newSampling) => {
	return async (dispatch) => {
		dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(API.samplings.create, newSampling)
			.then((response) => {
				const sampling = formatSamplings(response.data);
				dispatch({ type: ADD_NEW_SAMPLING, payload: sampling });
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "success",
						message: "Muestra creado con éxito.",
					},
				});
				return response && response.status;
			})
			.catch((err) => {
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const updateSampling = (updatedSampling, hide) => {
	return async (dispatch) => {
		dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
		const response = await axios
			.put(`${API.samplings.edit}${updatedSampling && updatedSampling._id}`, updatedSampling)
			.then((response) => {
				const sampling = formatSamplings(response.data);
				dispatch({ type: UPDATE_SAMPLING, payload: sampling });
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				if (!hide) {
					dispatch({
						type: SHOW_NOTIFICATION,
						payload: {
							show: true,
							status: "info",
							message: "Muestra actualizada.",
						},
					});
				}
				return response && response.status;
			})
			.catch((err) => {
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const deleteSamplings = (Ids) => {
	return async (dispatch) => {
		dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
		const response = await axios
			.delete(`${API.samplings.delete}`, { data: Ids })
			.then((response) => {
				dispatch({ type: DELETE_SAMPLING, payload: Ids });
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				dispatch({
					type: SHOW_NOTIFICATION,
					payload: {
						show: true,
						status: "info",
						message: "Muestra eliminado.",
					},
				});
				return response && response.status;
			})
			.catch((err) => {
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const fetchSamplingsByDate = (date) => {
	return async (dispatch) => {
		const response = await axios
			.get(`${API.samplings.byDate}${date}`)
			.then((response) => {
				const samplings = formatSamplings(response.data);
				dispatch({
					type: SET_SAMPLING_LIST,
					payload: keyBy(samplings, "_id"),
				});
				return response.status;
			})
			.catch((err) => err);
		return response;
	};
};

export const sendAlertMail = (alertsByEstablishment) => {
	return async (dispatch) => {
		dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(`${API.samplings.alerts}`, alertsByEstablishment)
			.then((response) => {
				if (response.status === 200 || response === 200) {
					dispatch({
						type: SHOW_NOTIFICATION,
						payload: {
							show: true,
							status: "info",
							message: "Muestra enviada.",
						},
					});
				}
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return response && response.status;
			})
			.catch((err) => {
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const sendPoolData = (data) => {
	return async (dispatch) => {
		dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(`${API.samplings.poolData}`, data)
			.then((response) => {
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return response && response;
			})
			.catch((err) => {
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};

export const sendalertsMail = (alertsByEstablishment) => {
	return async (dispatch) => {
		dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
		const response = await axios
			.post(`${API.samplings.alertsMail}`, alertsByEstablishment)
			.then((response) => {
				if (response.status === 200 || response === 200) {
					dispatch({
						type: SHOW_NOTIFICATION,
						payload: {
							show: true,
							status: "info",
							message: "Muestra enviada.",
						},
					});
				}
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return response && response.status;
			})
			.catch((err) => {
				dispatch({ type: SAMPLING_FORM_TOOGLE_LOADING });
				return err;
			});
		return response;
	};
};
